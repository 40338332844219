






























































































































































































import Vue from 'vue';
import moment from 'moment';
import _, { isString } from 'lodash';
import qs from 'qs';
import { Context } from '@nuxt/types';
import { ResponseCode } from '~/util/constant';
import { $cookies } from '~/util/api';
import {
  checkDevice,
  adminHost,
  handleNewsUrl,
  handleNodeServerError,
} from '~/util/common';
import GoodsItem from '~/components/Goods/item.vue';
import { TraceCode } from '~/util/trace';

export default Vue.extend({
  filters: {
    subIndustry(idx: number) {
      const index = idx % 8; // idx % 5;
      switch (index) {
        case 0:
          return 'transform: translateX(-8%);';
        case 1:
          return 'transform: translateX(-16%);';
        case 2:
          return 'transform: translateX(-42%);';
        case 3:
          return 'transform: translateX(-22%);';
        case 4:
          return 'transform: translateX(-58%);';
        case 5:
          return 'transform: translateX(-58%);';
        case 6:
          return 'transform: translateX(-58%);';
        case 7:
          return 'transform: translateX(-58%);';
      }
    },
    formatIndustry(val: string): string {
      if (!val) return '';
      const vals = val.split('/');
      return vals.slice(0, 2).join('/');
    },
    formatTime(val: string) {
      return moment(new Date(val)).format('YYYY-MM-DD HH:mm:ss');
    },
    description(item: Record<string, any>): any {
      if (item.description) {
        return item.description;
      } else {
        const reg = /<[^>]+>/gi;
        const description =
          (item.content ?? '') != ''
            ? item.content
                ?.replace(reg, '')
                ?.replace(/&nbsp;/g, '')
                .trim()
            : '';
        return description ? description?.slice(0, 100) + '...' : '';
      }
    },
  },
  components: { GoodsItem },
  // 校验params参数
  // validate(ctx: Context) {
  //   const { path } = ctx.route;
  //   const { keyword } = ctx.query;
  //   if (isString(keyword)) {
  //     // 搜索词不能包含 网址、網纸；只能输入字母和汉字、搜索词长度必须小于等于 50
  //     return (
  //       !/网址|網纸/.test(keyword) &&
  //       /^[0-9a-zA-Z\u4E00-\u9FA5 \(\)（）]+$/.test(keyword) &&
  //       keyword.length <= 50
  //     );
  //   } else {
  //     if (path === '/wiki' || path === '/wiki/') {
  //       return true;
  //     }
  //     const _validate =
  //       /wiki-(\d+)/.test(path);

  //     return _validate;
  //   }
  // },
  async asyncData(ctx) {
    try {
      const { keyword, sort, type: sortType } = ctx.query;

      const lang = _.get(ctx, 'query.lang', null);
      let langApi = 'cn';
      if (lang == 'zh') langApi = 'cn';
      if (lang == 'en') langApi = 'en';
      let apiErrorList: any = [];

      let { page = 1 } = ctx.params;

      const currentYear: number = new Date().getFullYear(); // 当前年份
      const currentMonth: number = new Date().getMonth(); // 当前月份
      let dateYearMonth: string = ''; // 年月 格式:202409

      const pageNum = isNaN(+page) ? 1 : +page;
      const pageSize = 12;

      // 搜索列表
      let listParams: Record<string, any> = {
        locale: langApi,
        pageNum,
        pageSize,
      };

      let requestList: any[] = [];

      requestList = [
        ctx.$axios.post('/api/wiki/exhibition/newList', {
          locale: langApi,
          pageNum: 1,
          pageSize: 10,
        }),
        ctx.$axios.post('/api/wiki/exhibition/hotList', {
          locale: langApi,
          sortHot: 'desc',
          pageNum: 1,
          pageSize: 10,
        }),
        ctx.$axios.get(`/api/industry/getAll?locale=${langApi}`),
        ctx.$axios.post('/api/newsApi/homePageList', listParams),
        ctx.$axios.post('/api/wiki/industryViews', {
          type: '近7天',
          pageNum: 1,
          pageSize: 10,
        }),
      ];

      const result: any[] = await Promise.all(requestList);
      const [policyNewsRes, hotFairRes, industryRes, wikiRes, industryListRes] =
        result;

      let policyNews = {};
      let newWikiList: any = [];

      if (policyNewsRes.code === ResponseCode.SUCCESS) {
        let list = policyNewsRes.data.records;
        if (list.length > 0) {
          let idList: any = [];
          list.map((i: any) => idList.push(i.id));
          await ctx.$axios
            .post(`/api/wiki/aliasList`, {
              exhibitionIdList: idList,
            })
            .then((aliasRes: any) => {
              if (aliasRes.code === ResponseCode.SUCCESS) {
                //
                let aliasList: any = aliasRes.data;
                let resList: any = [];
                list.map((item: any) => {
                  let findRes: any = aliasList.find(
                    (x: any) => x.exhibitionId == item.id && x.isPrimary == 1
                  );
                  if (!findRes) {
                    findRes = aliasList.find(
                      (x: any) => x.exhibitionId == item.id
                    );
                  }

                  if (findRes) {
                    resList.push({
                      id: findRes ? findRes.id : '',
                      exhibitionId: item.id,
                      exhibitionName: item.exhibitionName,
                      exhibitionAliasName: findRes
                        ? findRes.exhibitionAlias
                        : item.exhibitionName,
                      cityName: item.cityName, // findRes ? findRes.cityName : '',
                      lunchTime: item.lunchTime,
                    });
                  }
                });
                newWikiList = resList;
              }
            });
        }
      }

      let hotWikiList: any = [];
      if (hotFairRes.code === ResponseCode.SUCCESS) {
        let list = hotFairRes.data.records;

        if (list.length > 0) {
          let idList: any = [];
          list.map((i: any) => idList.push(i.exhibitionId));
          await ctx.$axios
            .post(`/api/wiki/aliasList`, {
              exhibitionIdList: idList,
            })
            .then((aliasRes: any) => {
              if (aliasRes.code === ResponseCode.SUCCESS) {
                let aliasList: any = aliasRes.data;
                let resList: any = [];
                list.map((item: any) => {
                  let findRes: any = aliasList.find(
                    (x: any) =>
                      x.exhibitionId == item.exhibitionId && x.isPrimary == 1
                  );
                  if (!findRes) {
                    findRes = aliasList.find(
                      (x: any) => x.exhibitionId == item.exhibitionId
                    );
                  }
                  if (findRes) {
                    resList.push({
                      id: findRes ? findRes.id : '',
                      exhibitionId: item.exhibitionId,
                      exhibitionName: item.exhibitionName,
                      exhibitionAliasName: findRes
                        ? findRes.exhibitionAlias
                        : item.exhibitionName,
                      cityName: item.cityName, // findRes ? findRes.cityName : '',
                      lunchTime: item.lunchTime,
                    });
                  }
                });
                hotWikiList = resList;
              } else {
                apiErrorList.push({
                  code: aliasRes.code,
                  message: aliasRes.message,
                });
              }
            });
        }
      } else {
        apiErrorList.push({
          code: hotFairRes.code,
          message: hotFairRes.message,
        });
      }

      let industry = [];
      if (industryRes.code === ResponseCode.SUCCESS) {
        industry = industryRes.data;
      }

      let wiki = {};

      if (wikiRes?.code === ResponseCode.SUCCESS && wikiRes.data) {
        wiki = wikiRes.data;

        if (pageNum > 1 && pageNum > wikiRes.data.pages) {
          return ctx.error({
            message: 'Page not found',
            path: ctx.route.fullPath,
            statusCode: 404,
          });
        }
      } else {
        apiErrorList.push({ code: wikiRes.code, message: wikiRes.message });
      }

      let secondIndustryList: any = [];
      let industryFairList: any = [];

      if (
        industryListRes.code === ResponseCode.SUCCESS &&
        industryListRes.data &&
        industryListRes.data.records
      ) {
        secondIndustryList = industryListRes.data.records || [];
        if (secondIndustryList.length > 0) {
          await ctx.$axios
            .post('/api/wiki/exhibition/hotList', {
              locale: langApi,
              industryId: secondIndustryList[0].id, // 第一个二级行业id
              pageNum: 1,
              pageSize: 8,
            })
            .then(async (res: any) => {
              if (res.code === ResponseCode.SUCCESS) {
                let list = res.data.records;

                if (list.length > 0) {
                  let idList: any = [];
                  list.map((i: any) => idList.push(i.exhibitionId));
                  await ctx.$axios
                    .post(`/api/wiki/aliasList`, {
                      exhibitionIdList: idList,
                    })
                    .then((aliasRes: any) => {
                      if (aliasRes.code === ResponseCode.SUCCESS) {
                        let aliasList: any = aliasRes.data;
                        let resList: any = [];
                        list.map((item: any) => {
                          let findRes: any = aliasList.find(
                            (x: any) =>
                              x.exhibitionId == item.exhibitionId &&
                              x.isPrimary == 1
                          );
                          if (!findRes) {
                            findRes = aliasList.find(
                              (x: any) => x.exhibitionId == item.exhibitionId
                            );
                          }

                          if (findRes) {
                            resList.push({
                              id: findRes ? findRes.id : '',
                              exhibitionId: item.exhibitionId,
                              exhibitionName: item.exhibitionName,
                              exhibitionAliasName: findRes
                                ? findRes.exhibitionAlias
                                : item.exhibitionName,
                              cityName: findRes ? findRes.cityName : '',
                              lunchTime: item.lunchTime,
                              description: item.profile
                                ? item.profile
                                    .replace(/<[^>]*>/g, '')
                                    .slice(0, 100) + '...'
                                : '',
                            });
                          }
                        });

                        industryFairList = resList;
                      } else {
                        // apiErrorList.push({
                        //   code: aliasRes.code,
                        //   message: aliasRes.message,
                        // });
                      }
                    });
                }
              } else {
                // apiErrorList.push({ code: res.code, message: res.message });
              }
            });
        }
      } else {
        // apiErrorList.push({
        //   code: industryListRes.code,
        //   message: industryListRes.message,
        // });
      }

      let tabIndustryActive: number =
        secondIndustryList.length > 0 ? secondIndustryList[0].id : '';

      // 内链
      let innerChainActive: number = 0;
      const innerChainAllRes: any = await Promise.all([
        ctx.$axios.post('/api/innerChain/page', {
          category: '热门地区',
          pageSize: 16,
        }),
        ctx.$axios.post('/api/innerChain/page', {
          category: '热门行业',
          pageSize: 16,
        }),
        ctx.$axios.post('/api/innerChain/page', {
          category: '热门展会',
          pageSize: 16,
        }),
      ]);

      const [innerChainAreaRes, innerChainIndustryRes, innerChainExhRes] =
        innerChainAllRes;
      let innerChainAreaList: any[] = [];
      let innerChainIndustryList: any[] = [];
      let innerChainExhList: any[] = [];

      if (innerChainAreaRes.code === ResponseCode.SUCCESS) {
        innerChainAreaList = innerChainAreaRes.data.records;
      }
      if (innerChainIndustryRes.code === ResponseCode.SUCCESS) {
        innerChainIndustryList = innerChainIndustryRes.data.records;
        let len = innerChainIndustryList.length;
        if (len < 16) {
          let ctxIndustry: any = await Promise.all([
            ctx.$axios.post('/api/innerChain/page', {
              category: '热门行业',
              pageSize: 16 - len,
            }),
          ]);

          const [innerChainArea2Res] = ctxIndustry;
          if (innerChainArea2Res.code === ResponseCode.SUCCESS) {
            innerChainIndustryList = innerChainIndustryList.concat(
              innerChainArea2Res.data.records
            );
          }
        }
      }
      if (innerChainExhRes.code === ResponseCode.SUCCESS) {
        innerChainExhList = innerChainExhRes.data.records;
        let len = innerChainExhList.length;
        if (len < 16) {
          let ctxIndustry: any = await Promise.all([
            ctx.$axios.post('/api/innerChain/page', {
              category: '热门展会',
              pageSize: 16 - len,
            }),
          ]);

          const [innerChainExh2Res] = ctxIndustry;
          if (innerChainExh2Res.code === ResponseCode.SUCCESS) {
            innerChainExhList = innerChainExhList.concat(
              innerChainExh2Res.data.records
            );
          }
        }
      }

      let interfaceResponseTime = ctx.store.state.global.interfaceResponseTime;
      // 设置一个自定义响应头(nuxtTraceId 记录日志位置)
      if (ctx.res) ctx.res.setHeader('nuxtTraceId', interfaceResponseTime);
      if (ctx.$winstonLog) ctx.$winstonLog.info(interfaceResponseTime);
      ctx.store.commit('global/setInterfaceResponseTime', '');

      if (apiErrorList.length > 0)
        handleNodeServerError(ctx, JSON.stringify(apiErrorList));

      return {
        lang,
        langApi,
        keyword,
        pageNum,
        pageSize,
        newWikiList,
        hotWikiList,
        secondIndustryList,
        industryFairList,
        industry,
        wiki,
        filter: listParams,
        tabIndustryActive,
        innerChainActive,
        innerChainAreaList,
        innerChainIndustryList,
        innerChainExhList,
      };
    } catch (e) {
      ctx.error(e as any);
    }
  },
  data(): Record<string, any> {
    return {
      keyword: '',
      type: 0,
      area: 0,
      province: 0,
      city: 0,
      date: '0',
      value: '',
      wiki: {},
      industry: [],
      tabIndustryActive: '',
      industryFairList: [],
    };
  },
  computed: {},
  created(): void {},
  mounted() {
    // if (checkDevice() == 'h5') {
    //   let url = `https://m.jufair.com${this.$route.path}`;
    //   window.location.replace(url);
    // }
  },
  methods: {
    handleNewsUrl,
    handleInsdutryTab(e: any) {
      this.tabIndustryActive = e;
    },
    handleHotTab(e: any) {
      this.innerChainActive = e;
    },
    handleNav(industryId: any) {
      this.tabIndustryActive = industryId;
      this.$axios
        .post('/api/wiki/exhibition/hotList', {
          locale: this.langApi,
          industryId: industryId,
          pageNum: 1,
          pageSize: 8,
        })
        .then((res: any) => {
          if (res.code === ResponseCode.SUCCESS) {
            let list = res.data.records;

            if (list.length > 0) {
              let idList: any = [];
              list.map((i: any) => idList.push(i.exhibitionId));
              this.$axios
                .post(`/api/wiki/aliasList`, {
                  exhibitionIdList: idList,
                })
                .then((aliasRes: any) => {
                  if (aliasRes.code === ResponseCode.SUCCESS) {
                    let aliasList: any = aliasRes.data;
                    let resList: any = [];
                    list.map((item: any) => {
                      let findRes: any = aliasList.find(
                        (x: any) =>
                          x.exhibitionId == item.exhibitionId &&
                          x.isPrimary == 1
                      );
                      if (!findRes) {
                        findRes = aliasList.find(
                          (x: any) => x.exhibitionId == item.exhibitionId
                        );
                      }

                      resList.push({
                        id: findRes ? findRes.id : '',
                        exhibitionId: item.exhibitionId,
                        exhibitionName: item.exhibitionName,
                        exhibitionAliasName: findRes
                          ? findRes.exhibitionAlias
                          : item.exhibitionName,
                        cityName: findRes ? findRes.cityName : '',
                        lunchTime: item.lunchTime,
                        description: item.profile
                          ? item.profile.replace(/<[^>]*>/g, '').slice(0, 100) +
                            '...'
                          : '',
                      });
                    });
                    this.industryFairList = resList;
                  }
                });
            }
          }
        });
    },
    // 获取展会列表
    handleGetFairList({ sortStr, sortType }: Record<string, any>) {
      const params: any = _.cloneDeep(this.$route.query);

      const sort = this.sortType === 'desc' ? 'asc' : 'desc';
      if (sortStr && sortType) {
        this[sortStr].sortType = sort;

        params.sort = sortStr;
        params.type = sort;
      }
      // window.location.href = `${this.$route.path}?${qs.stringify(params)}`
      // 重置页数为第1页
      window.location.href = `/wiki-0-1/?${qs.stringify(params)}`;
      // this.$router.push(`${this.$route.path}?${qs.stringify(params)}`);

      // const res: any = await this.$axios.post('/api/exhibition/list', params);
      // if (res.code === ResponseCode.SUCCESS) {
      //   this.fair = res.data;
      // }
    },

    changePager(page: number) {
      // const { firstIndustryId, secondIndustryId, area, province, city, date } =
      //   this;

      this.$router.push({
        path: `/wiki-${page}/`,
        query: this.$route.query,
      });
    },
  },
  head(): Record<string, any> {
    const { page }: any = this.$route.params;
    const { keyword = '' } = this.$route.query;

    let title = '展会百科';
    let keywords = '展会百科';
    let description = '展会百科';

    let pageText = '';
    let _year = moment().year();
    let _month = 0;
    let _date = '';

    const currentYear = new Date().getFullYear(); // 当前年份
    const currentMonth = new Date().getMonth(); // 当前月份
    let dateYearMonth = ''; // 年月 格式:202409

    title = `${title}${pageText}-聚展`;
    keywords = `${keyword}`;
    description = ``;

    return {
      title,
      meta: [
        // {
        //   name: 'mobile-agent',
        //   'http-equiv': 'mobile-agent',
        //   content: `format=html5; url=https://m.jufair.com/exhibition-${firstIndustryId}-${secondIndustryId}-${continentId}-${countryId}-${cityId}-${date}-${page}`,
        // },
        {
          name: 'keywords',
          content: keywords,
        },
        {
          name: 'description',
          content: description,
        },
      ],
      // link: [
      //   {
      //     rel: 'canonical',
      //     href: `https://www.jufair.com/wiki-1/`,
      //   },
      // ],
    };
  },
});
