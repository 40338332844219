

























































































































import Vue from 'vue';
import moment from 'moment';
import _, { isString } from 'lodash';
import qs from 'qs';
import { Context } from '@nuxt/types';
import { ResponseCode } from '~/util/constant';
import { $cookies } from '~/util/api';
import {
  checkDevice,
  adminHost,
  bucketCdnHost,
  bucketHost,
  handleNewsUrl,
  handleNodeServerError,
} from '~/util/common';
import GoodsItem from '~/components/Goods/item.vue';
import { TraceCode } from '~/util/trace';

export default Vue.extend({
  filters: {
    formatIndustry(val: string): string {
      if (!val) return '';
      const vals = val.split('/');
      return vals.slice(0, 2).join('/');
    },
    subIndustry(idx: number) {
      const index = idx % 8; // idx % 5;
      switch (index) {
        case 0:
          return 'transform: translateX(-8%);';
        case 1:
          return 'transform: translateX(-16%);';
        case 2:
          return 'transform: translateX(-42%);';
        case 3:
          return 'transform: translateX(-22%);';
        case 4:
          return 'transform: translateX(-58%);';
        case 5:
          return 'transform: translateX(-58%);';
        case 6:
          return 'transform: translateX(-58%);';
        case 7:
          return 'transform: translateX(-58%);';
      }
    },
    formatTime(val: string) {
      return moment(new Date(val)).format('YYYY-MM-DD HH:mm:ss');
    },
    description(item: Record<string, any>): any {
      if (item.description) {
        return item.description;
      } else {
        const reg = /<[^>]+>/gi;
        const description =
          (item.content ?? '') != ''
            ? item.content
                ?.replace(reg, '')
                ?.replace(/&nbsp;/g, '')
                .trim()
            : '';
        return description ? description?.slice(0, 100) + '...' : '';
      }
    },
    logo(value: string): string {
      let parse: any = value;
      if (typeof value === 'string') {
        try {
          parse = JSON.parse(value)[0]
            .replace('http://', 'https://')
            .replace(bucketHost, bucketCdnHost);
        } catch (error) {
          parse = value;
          console.error(error);
        }
      }
      return parse + '!logo';
    },
  },
  components: { GoodsItem },
  // 校验params参数
  // validate(ctx: Context) {
  //   const { path } = ctx.route;
  //   const { keyword } = ctx.query;
  //   if (isString(keyword)) {
  //     // 搜索词不能包含 网址、網纸；只能输入字母和汉字、搜索词长度必须小于等于 50
  //     return (
  //       !/网址|網纸/.test(keyword) &&
  //       /^[0-9a-zA-Z\u4E00-\u9FA5 \(\)（）]+$/.test(keyword) &&
  //       keyword.length <= 50
  //     );
  //   } else {
  //     if (path === '/wiki' || path === '/wiki/') {
  //       return true;
  //     }
  //     const _validate =
  //       /wiki-(\d+)/.test(path);

  //     return _validate;
  //   }
  // },
  async asyncData(ctx) {
    try {
      const { keyword, sort, type: sortType } = ctx.query;

      const lang = _.get(ctx, 'query.lang', null);
      let langApi = 'cn';
      if (lang == 'zh') langApi = 'cn';
      if (lang == 'en') langApi = 'en';
      let apiErrorList: any = [];

      let {
        s: secondIndustryId = 0, // 二级行业id
        page = 1,
      } = ctx.params;

      const currentYear: number = new Date().getFullYear(); // 当前年份
      const currentMonth: number = new Date().getMonth(); // 当前月份
      let dateYearMonth: string = ''; // 年月 格式:202409

      const pageNum = isNaN(+page) ? 1 : +page;
      const pageSize = 12;

      // 搜索列表
      let listParams: Record<string, any> = {
        locale: langApi,
        pageNum,
        pageSize,
      };
      let params: any = {
        locale: langApi,
        pageNum,
        pageSize,
      };
      if (+secondIndustryId > 0) {
        params['industryId'] = secondIndustryId;
      }

      let requestList: any[] = [];

      requestList = [
        ctx.$axios.get(`/api/industry/getAll?locale=${langApi}`),
        ctx.$axios.post('/api/wiki/list', params),
        ctx.$axios.post('/api/newsApi/homePageList', listParams),
      ];

      const result: any[] = await Promise.all(requestList);
      const [industryRes, fairRes, wikiRes] = result;

      let secondIndustryName: string = '';
      let industry = [];
      if (industryRes.code === ResponseCode.SUCCESS) {
        let list: any = industryRes.data;
        
        let len: number = list.length;
        industry = industryRes.data;
        // 查询行业名称
        for (let i = 0; i < len; i++) {
          let secondList = list[i].children;
          for (let x = 0; x < secondList.length; x++) {
            if (secondList[x].id == secondIndustryId) {
              secondIndustryName = secondList[x].industryName;
              break;
            }
          }
        }
      }

      let fair: any = {
        total: 0,
        records: [],
      };
      
      if (fairRes?.code === ResponseCode.SUCCESS) {
        let list = fairRes.data.records;
        
        list.map((item: any) => {
          item['cover'] = '';
          if (item.logo) {
            let arr: any = JSON.parse(item.logo);
            item['cover'] = arr.length > 0 ? arr[0] : '';
          }
          item['description'] = item.profile
            ? item.profile.replace(/<[^>]*>/g, '').slice(0, 100) + '...'
            : '';
        });
        
        fair['total'] = fairRes.data.total;
        fair['records'] = list;
        if (pageNum > 1 && pageNum > fairRes.data.pages) {
          return ctx.error({
            message: 'Page not found',
            path: ctx.route.fullPath,
            statusCode: 404,
          });
        }
      } else {
        apiErrorList.push({ code: fairRes.code, message: fairRes.message });
      }

      let wiki = {};
      
      if (wikiRes?.code === ResponseCode.SUCCESS && wikiRes.data && wikiRes.data.records) {
        wiki = wikiRes.data;

        if (pageNum > 1 && pageNum > wikiRes.data.pages) {
          return ctx.error({
            message: 'Page not found',
            path: ctx.route.fullPath,
            statusCode: 404,
          });
        }
      } else {
        apiErrorList.push({ code: wikiRes.code, message: wikiRes.message });
      }

      let interfaceResponseTime = ctx.store.state.global.interfaceResponseTime;
      
      // 设置一个自定义响应头(nuxtTraceId 记录日志位置)
      if (ctx.res) ctx.res.setHeader('nuxtTraceId', interfaceResponseTime);
      if (ctx.$winstonLog) ctx.$winstonLog.info(interfaceResponseTime);
      ctx.store.commit('global/setInterfaceResponseTime', '');

      if (apiErrorList.length > 0)
        handleNodeServerError(ctx, JSON.stringify(apiErrorList));

      return {
        lang,
        langApi,
        keyword,
        pageNum,
        pageSize,
        industry,
        fair,
        wiki,
        secondIndustryId,
        secondIndustryName,
        filter: listParams,
      };
    } catch (e) {
      ctx.error(e as any);
    }
  },
  data(): Record<string, any> {
    return {
      keyword: '',
      type: 0,
      area: 0,
      province: 0,
      city: 0,
      date: '0',
      value: '',
      wiki: {},
      industry: [],
    };
  },
  computed: {},
  created(): void {},
  mounted() {
    // if (checkDevice() == 'h5') {
    //   let url = `https://m.jufair.com${this.$route.path}`;
    //   window.location.replace(url);
    // }
  },
  methods: {
    handleNewsUrl,
    // 获取展会列表
    handleGetFairList({ sortStr, sortType }: Record<string, any>) {
      const params: any = _.cloneDeep(this.$route.query);

      const sort = this.sortType === 'desc' ? 'asc' : 'desc';
      if (sortStr && sortType) {
        this[sortStr].sortType = sort;

        params.sort = sortStr;
        params.type = sort;
      }
      // window.location.href = `${this.$route.path}?${qs.stringify(params)}`
      // 重置页数为第1页
      window.location.href = `/wiki-0-1/`;
      // this.$router.push(`${this.$route.path}?${qs.stringify(params)}`);

      // const res: any = await this.$axios.post('/api/exhibition/list', params);
      // if (res.code === ResponseCode.SUCCESS) {
      //   this.fair = res.data;
      // }
    },

    changePager(page: number) {
      const { secondIndustryId } = this;

      this.$router.push({
        path: `/wiki-${secondIndustryId}-${page}/`,
        query: this.$route.query,
      });
    },
  },
  head(): Record<string, any> {
    const { page }: any = this.$route.params;
    const { keyword = '' } = this.$route.query;

    let title = '展会百科';
    let keywords = '展会百科';
    let description = '';

    let pageText = '';
    let _year = moment().year();
    let _month = 0;
    let _date = '';

    const currentYear = new Date().getFullYear(); // 当前年份
    const currentMonth = new Date().getMonth(); // 当前月份
    let dateYearMonth = ''; // 年月 格式:202409

    title = `${title}${pageText}-聚展`;
    keywords = ``;
    description = ``;

    return {
      title,
      meta: [
        // {
        //   name: 'mobile-agent',
        //   'http-equiv': 'mobile-agent',
        //   content: `format=html5; url=https://m.jufair.com/exhibition-${firstIndustryId}-${secondIndustryId}-${continentId}-${countryId}-${cityId}-${date}-${page}`,
        // },
        {
          name: 'keywords',
          content: keywords,
        },
        {
          name: 'description',
          content: description,
        },
      ],
      // link: [
      //   {
      //     rel: 'canonical',
      //     href: `https://www.jufair.com/wiki-1/`,
      //   },
      // ],
    };
  },
});
